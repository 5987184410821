import Vue from 'vue';
import store from '@/state/store';
import BaseUrl from '../components/constants/index';

import VueRouter from 'vue-router';

Vue.use(VueRouter);

const UserData = sessionStorage.getItem('userData');
let userInfo = null;
if (UserData) {
    userInfo = BaseUrl.Decryption(UserData);
}

const isAuthorized = userInfo?.userDetails?.phoneNumber;

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/pages/dashboard/index'),
        meta: { requiresAuth: true },
    },
    {
        path: '/rider/add',
        name: 'rider-add',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/riders/AddRider'),
    },
    {
        path: '/riders',
        name: 'riders',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/riders/ListOfRiders'),
    },
    {
        path: '/rider/:Id',
        name: 'rider_detail',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/riders/ViewRiderDetails'),
    },
    {
        path: '/patients',
        name: 'patients',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/patients/ListOfPatients'),
    },
    {
        path: '/patients/:Id',
        name: 'patients-Id',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/patients/ViewPatient'),
    },
    {
        path: '/doctors',
        name: 'doctors',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/doctors/ListOfDoctors'),
    },
    {
        path: '/doctors/:Id',
        name: 'doctors-Id',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/doctors/ViewDoctor'),
    },
    {
        path: '/reviews',
        name: 'reviews',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/reviews/ManageReviews'),
    },
    {
        path: '/reviews/:Id',
        name: 'view-reviews',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/reviews/ViewReviews'),
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/orders/ManageOrders'),
    },
    {
        path: '/pharmacy/expired-drugs',
        name: 'Expired Drugs',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/products/ManageExpiredProducts'),
    },
    {
        path: '/pharmacy/orders',
        name: 'orders',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/pharmacy/orders/ManageOrders'),
    },
    {
        path: '/grocery/order/:Id',
        name: 'order-grocery',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/orders/ViewOrderDetail'),
    },
    {
        path: '/pharmacy/order/:Id',
        name: 'order-pharmacy',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/pharmacy/orders/ViewOrderDetail'),
    },
    {
        path: '/recipe/add',
        name: 'add recipe',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/recipes/AddRecipe'),
    },
    {
        path: '/recipe/:Id',
        name: 'recipe_detail',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/recipes/ViewRecipe'),
    },
    {
        path: '/recipe',
        name: 'recipe',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/recipes/ManageRecipes'),
    },
    {
        path: '/prescription/:Id',
        name: 'prescription_detail',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/prescriptions/ViewAndUpdatePrescription'),
    },
    {
        path: '/prescriptions',
        name: 'prescription',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/prescriptions/ManagePrescriptions'),
    },
    {
        path: '/pharmacy',
        name: 'drugs',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/products/ManageProducts'),
    },
    {
        path: '/grocery/add',
        name: 'grocery',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/groceries/AddGroceries'),
    },
    {
        path: '/grocery/:Id',
        name: 'grocery',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/groceries/ViewGroceries'),
    },
    {
        path: '/groceries',
        name: 'groceries',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/groceries/ManageGroceries'),
    },
    {
        path: '/user/add',
        name: 'AddUser',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/users/AddUser'),
    },
    {
        path: '/users/getall',
        name: 'AddUser',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/users/UserList'),
    },
    {
        path: '/suppliers',
        name: 'suppliers',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/suppliers/ManageSupplier'),
    },
    {
        path: '/brands',
        name: 'Brands',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/brands/ManageBrands'),
    },
    {
        path: '/categories/:Id',
        name: 'category',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/categories/ViewCategoryDetail'),
    },
    {
        path: '/categories',
        name: 'categories',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/categories/ManageCategories'),
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/products/ManageProducts'),
    },
    {
        path: '/product/add',
        name: 'add-product',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/products/AddProduct'),
    },
    {
        path: '/product/:Id',
        name: 'view-product',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/products/ViewProduct'),
    },
    {
        path: '/branches',
        name: 'branches',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/branchs/ManageBranches'),
    },
    {
        path: '/coupons',
        name: 'coupons',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../views/pages/setups/coupons/ManageCoupons'),
    },
    //
];

const router = new VueRouter({
    // mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
    if (requiresAuth && !isAuthorized) {
        //
        // If the route requires authentication and the user is not logged in
        console.log('User is not logged in. Redirecting to login page...');
        // Example of redirecting to the login page
        next({ name: 'login' });
    } else {
        // If the route does not require authentication or the user is logged in
        next();
    }
});

export default router;
