import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import simplebar from "simplebar-vue";
import VueTour from 'vue-tour'
import vco from "v-click-outside"
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from './i18n'

import router from './router'
import store from '@/state/store'

import VueApexCharts from 'vue-apexcharts'
import VueStringFilter from 'vue-string-filter'
import Lightbox from 'vue-easy-lightbox'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
  moment,
})

Vue.config.productionTip = false

Vue.use(vco)



// As a plugin
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import VueDraggable from "vue-draggable";
Vue.use(VueDraggable);
Vue.use(VueQuillEditor)
Vue.use(VueMask);

import VueSlideBar from 'vue-slide-bar'
 
Vue.component('VueSlideBar', VueSlideBar)

import { initFirebaseBackend } from './authUtils';

import { configureFakeBackend } from './helpers/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)


import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

import axios from 'axios'
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;


import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);

Vue.use(Vuelidate)

import EnlargeableImage from '@diracleo/vue-enlargeable-image';
Vue.use(EnlargeableImage)

Vue.use(require('vue-chartist'))
Vue.component('simplebar', simplebar)
Vue.use(VueStringFilter)
Vue.use(VueTour)
Vue.use(Lightbox)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
    v: 'GOOGLE_MAPS_VERSION',
  },
  installComponents: true
});

import "@/assets/scss/app.scss";


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
