import axios from "axios";
import CryptoJS from 'crypto-js';

const URL = "https://api.emenaster.com/v1/api"; // LIVE
//const URL = "https://dad414d802bf.ngrok.app/v1/api";

const ServerError  = "Kindly try again later";



const classificationType = Object.freeze({
    PHARMACY : 'PHARMACY',
    GROCERIES :'GROCERIES',
    RECIPE :'RECIPE'
})

function formatMoney(amount,currency) {
    return amount.toLocaleString('en-US', { style: 'currency', currency: currency });
}

const secretKey = 'FromThedayOfJOHN@1223NOW.TheKingdo4654594549405'; // Keep this key secure

function ASC_Encryption(data) {
    // const originalData = { sensitive: 'data' };
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return encryptedData;
}


function ASC_Decryption(storedData) {
    const decryptedBytes = CryptoJS.AES.decrypt(storedData, secretKey);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData
}


const UserData = sessionStorage.getItem("userData")
 let currentUserData = null;
if(UserData) {
    currentUserData = ASC_Decryption(UserData);
}


axios.interceptors.response.use(
    response => {
      // Modify response data here if needed
      return response;
    },
    error => {
      // Handle response errors here
      if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return Promise.resolve(error.response)
        
      }
      return Promise.reject(error);
    }
  );

const axiosInstanceForBearer = axios.create(
    {
        baseURL :URL,
        withCredentials : true,
        headers : {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "http://dashboard.emenaster.com",
            'Access-Control-Allow-Credentials':true,
            "Authorization": "Bearer " + currentUserData?.jwt
         }
    }

)

function formatMoneyWithoutCurrency(amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export default {
    URL : URL,
    serverError : ServerError,
    formatMoney : formatMoney,
    AxiosBearer : axiosInstanceForBearer,
    formatMoneyWithoutCurrency : formatMoneyWithoutCurrency,
    Encryption : ASC_Encryption,
    Decryption : ASC_Decryption,
    Secret : secretKey,
    ClassType : classificationType,
    MapBoxPublicKey : 'pk.eyJ1IjoiZWxjZW50aW5vIiwiYSI6ImNsbndkZWs4azA2cTcybm14aWl0eWRnenEifQ.v5Bai_miKQebDRmlbHp2og'
}